const config = {
    soundstyle: 'classic',
    appName: 'Wintrado Dev',
    appTitle: 'Wintrado - WebTrader Dev',
    feedApi: {
        url: 'wss://gateway.dev.wintrado.com',
    },
    baseURL: 'https://gateway.dev.wintrado.com',
    cashier: {
        useIframe: true,
        baseURL: 'https://cashier.dev.wintrado.com',
        frontendURL: 'https://app.dev.wintrado.com',
    },
    oAuth: {
        clientId: 'webtrader-wintrado',
        clientSecret: 'rpjsSJvu1HIJvDCe',
    },
    affiliatePortalUrl: 'https://affiliate.dev.wintrado.com',
};
export default config;
