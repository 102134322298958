import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _t from "counterpart";
import React, { useEffect, useState } from "react";
import config from "config";
import MainGrid from "../components/MainGrid";
import LoginGrid from "../components/LoginGrid.tsx";
import { actions, useAppDispatch, useAppSelector } from "wintrado-api";
import ErrorMessage from "../components/main/ErrorMessage";
import SoundEffectPlayer from "../components/global/SoundEffectPlayer";
import { isNotChrome, isStandalone, releaseVersion, requestOAuthLogin, setReferralParam } from "../helpers";
import { OfflineNotice } from "components/global/OfflineNotice";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import { setSagaErrorLogger } from "wintrado-api/src/utils/logger";
import PopupNotifications from "components/PopupNotification/PopupNotifications";
import PopupNotificationManager from "components/PopupNotification/PopupNotificationManager";
import { ErrorScreen } from "components/global/ErrorScreen";
import DebugPopup from "components/main/DebugPopup";
import PopupAlert from "components/PopupAlert/PopupAlert";
import PopupAlertManager from "components/PopupAlert/PopupAlertManager";
import { MaintenanceScreen } from "components/global/MaintenanceScreen";
import { setMomentLocale } from "utils/locales";
import { useQueryParams } from "utils/hooks";
import PoorConnection from "./PoorConnection/PoorConnection";
require('normalize.css/normalize.css');
require('sass/app.scss');
require(`sass/themes/${__BRAND__}.scss`);
const Main = () => {
    const [locale, setLocale] = useState(_t.getLocale());
    const [hasError, setHasError] = useState(false);
    const queryParams = useQueryParams();
    const error = useAppSelector((state) => state.error);
    const refreshToken = useAppSelector((state) => state.user.refreshToken);
    const dontShowChromeInstallCTA = useAppSelector((state) => state.settings.dontShowChromeInstallCTA);
    const language = useAppSelector((state) => state.settings.language);
    const soundstyle = useAppSelector((state) => state.settings.soundstyle);
    const isOnline = useAppSelector((state) => state.connection.online);
    const apiError = useAppSelector((state) => state.connection.apiError);
    const apiErrorReason = useAppSelector((state) => state.connection.apiErrorReason);
    const maintenanceMode = useAppSelector((state) => state.maintenanceMode);
    const initialConnectionFailed = useAppSelector((state) => state.connection.initialConnectionFailed);
    const loginTimedOut = useAppSelector((state) => state.events.loginTimedOut);
    const dispatch = useAppDispatch();
    const logError = (error, errorInfo) => {
        if (process.env.NODE_ENV === 'development') {
            console.error(error);
            console.error(errorInfo);
        }
        else {
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        }
    };
    const handleErrorReceived = () => {
        let showError = true;
        if (error.type !== null && error.type.toLowerCase() === 'outside_of_trading_hours') {
            dispatch(actions.showMarketClosedErrorModal());
            showError = false;
        }
        return (_jsx(ErrorMessage, { visible: error.type !== null && showError, title: error.type, message: error.message, extra: error.extra }));
    };
    const openChromeDownloadPage = (e) => {
        e.preventDefault();
        window.open('https://google.com/chrome');
    };
    const setDontShowChromeInstallCTA = (e) => {
        e.preventDefault();
        dispatch(actions.setDontShowChromeInstallCTA());
    };
    useEffect(() => {
        if (isNotChrome() && !dontShowChromeInstallCTA) {
            PopupNotificationManager.showNotification({
                id: 'chrome',
                primaryButtonTitleKey: 'main.download',
                onPrimaryButtonClicked: openChromeDownloadPage,
                secondaryButtonTitleKey: 'main.do_not_show_again',
                onSecondaryButtonClicked: setDontShowChromeInstallCTA,
                descriptionKey: 'main.chrome_installcta_description',
                descriptionExtra: { appName: config.appName },
            });
        }
        else {
            PopupNotificationManager.hideNotification('chrome');
        }
    });
    useEffect(() => {
        setSagaErrorLogger((error, info) => {
            logError(error, info);
            setHasError(true);
        });
    }, []);
    useEffect(() => {
        const handleVisibilityChange = (e) => {
            if (isStandalone()) {
                if (e.target.hidden) {
                    SoundEffectPlayer.muteAllSounds();
                }
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    useEffect(() => {
        const setSoundstyleFromConfig = () => {
            if (!soundstyle && Boolean(config.soundstyle)) {
                dispatch(actions.setSoundstyle(config.soundstyle));
            }
        };
        dispatch(actions.setReleaseVersion(releaseVersion()));
        if (queryParams.demo) {
            Sentry.captureException(new Error('Currently ignoring demo query param'));
        }
        if (queryParams.ref && !Array.isArray(queryParams.ref)) {
            setReferralParam(queryParams.ref);
        }
        if (queryParams.request_login) {
            requestOAuthLogin(language);
        }
        if (queryParams.code && queryParams.state && !Array.isArray(queryParams.code)) {
            if (window.sessionStorage.getItem('state') === queryParams.state) {
                dispatch(actions.requestOAuthTokens(queryParams.code, window.location.origin));
                history.replaceState({}, document.title, '/');
            }
        }
        if (queryParams.instrument && !Array.isArray(queryParams.instrument)) {
            dispatch(actions.setInstrumentToPreselect(queryParams.instrument));
        }
        if (queryParams.logoff) {
            dispatch(actions.logoff({ userRequested: true, withOAuth: false }));
        }
        setSoundstyleFromConfig();
        return () => {
            PopupNotificationManager.unregisterContainer();
        };
        // We intentionally don't want to add deps here - we want this effect to effectively run as componentDidMount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const setLanguage = (lang) => {
            if (locale !== lang) {
                _t.setLocale(lang);
                setLocale(_t.getLocale());
                setMomentLocale(lang);
            }
        };
        setLanguage(language);
    }, [language, locale]);
    useEffect(() => {
        if (apiError && apiErrorReason) {
            logError(apiErrorReason, { type: 'ApiError' });
            if (initialConnectionFailed) {
                setHasError(true);
            }
        }
    }, [apiError, apiErrorReason]);
    if (hasError) {
        return _jsx(ErrorScreen, {});
    }
    if (maintenanceMode) {
        return _jsx(MaintenanceScreen, {});
    }
    if (loginTimedOut) {
        return _jsx(PoorConnection, {});
    }
    return (_jsx(ErrorBoundary, Object.assign({ fallback: _jsx(ErrorScreen, {}) }, { children: _jsxs("div", Object.assign({ id: "main", className: "index" }, { children: [refreshToken ? _jsx(MainGrid, {}, locale) : _jsx(LoginGrid, {}, locale), _jsx("div", { id: "modal-container" }), handleErrorReceived(), _jsx(OfflineNotice, { visible: !isOnline }), _jsx(SoundEffectPlayer, {}), _jsx(DebugPopup, {}), _jsx(PopupNotifications, { ref: (ref) => {
                        if (ref) {
                            PopupNotificationManager.registerContainer(ref);
                        }
                    } }), _jsx(PopupAlert, { ref: (ref) => {
                        if (ref) {
                            PopupAlertManager.registerContainer(ref);
                        }
                    } })] })) })));
};
export default React.memo(Main);
