import React from "react";

import { NavLink } from "react-router-dom";
import _t from "counterpart";
import chartIcon from "../../icons/chart.svg?url";
import dailyOverviewIcon from "../../icons/daily_overview.svg?url";
import settingsIcon from "../../icons/settings.svg?url";
import coinsIcon from "../../icons/coins_bw.svg?url";
import copyTradingIcon from "../../icons/copy_trading.svg?url";
import AnyLink from "components/global/AnyLink";
import { connect } from "react-redux";
import { selectors } from "wintrado-api";
import { wintradoHomeUrl } from "../../constants";
import Tooltip from "components/Tooltip/Tooltip";
import { getBrandLogo } from "components/BrandLogo/BrandLogo";

class SidebarLeft extends React.PureComponent {
	render() {
		const { supportsCoins, supportsCopytrading, greyLabelLogo } = this.props;


		const logoUrl = greyLabelLogo?.data?.squareLogo ?? getBrandLogo(false);

		return (
			<section id="sidebarLeft" className="sidemenu">
				{!greyLabelLogo.isLoading && (
					<NavLink exact to="/" className="logo">
						<h1 style={{ backgroundImage: `url(${logoUrl})` }}>WebTrader</h1>
					</NavLink>
				)}

				<div className="powered-by">
					<span className="powered-by__label">{_t('main.powered_by')}</span>
					<AnyLink className="powered-by__link" to={wintradoHomeUrl}>
						Wintrado
					</AnyLink>
				</div>

				<ul>
					<li className="no-top">
						<Tooltip content={_t.translate('page.trading')} side="right">
							<NavLink exact to="/">
								<img src={chartIcon} alt="chart-icon" />
							</NavLink>
						</Tooltip>
					</li>
					<li>
						<Tooltip content={_t.translate('page.account_statement')} side="right">
							<NavLink to="/account-statement">
								<img src={dailyOverviewIcon} alt="account-statement-icon" />
							</NavLink>
						</Tooltip>
					</li>
					{supportsCopytrading && (
						<li>
							<Tooltip content={_t.translate('page.copy_trading')} side="right">
								<NavLink to="/copy-trading/strategies">
									<img src={copyTradingIcon} alt="copy-trading-icon" />
								</NavLink>
							</Tooltip>
						</li>
					)}
					{supportsCoins && (
						<li>
							<Tooltip content={_t.translate('page.coins')} side="right">
								<NavLink to="/coins">
									<img src={coinsIcon} alt="coins-icon" />
								</NavLink>
							</Tooltip>
						</li>
					)}
					<li className="extra m-t-2">
						<Tooltip content={_t.translate('main.settings')} side="right">
							<NavLink to="/settings">
								<img src={settingsIcon} alt="settings-icon" />
							</NavLink>
						</Tooltip>
					</li>
				</ul>
			</section>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		greyLabelLogo: state.greyLabelLogo,
		token: state.user.token,
		supportsCoins: selectors.supportsCoinsSelector(state),
		supportsCopytrading: selectors.supportsCopytrading(state),
	};
};

export default connect(mapStateToProps, null)(SidebarLeft);
