import React, { useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import BalanceStat from 'components/main/BalanceStat';
import CoinsStat from 'components/main/CoinsStat';
import RankStat from 'components/main/RankStat';
import LoyaltyStat from 'components/LoyaltyStat/LoyaltyStat';
import { useMediaQuery } from 'react-responsive';
import { useAppSelector } from 'wintrado-api';
import BrandLogo from "components/BrandLogo/BrandLogo";

const MobileHeader = (props) => {
	const history = useHistory();
	// TODO improve design to avoid hiding Loyalty based on screen height
	const isShortScreen = useMediaQuery({ maxHeight: 660 });

	const renderStats = () => {
		const { totalPL, balance, accountCurrency, equity, freeMargin, marginCall, coinsBalance, supportsCoins } = props;

		return (
			<div className="stat__holder">
				<BalanceStat
					totalPL={totalPL}
					balance={balance}
					accountCurrency={accountCurrency}
					equity={equity}
					freeMargin={freeMargin}
					marginCall={marginCall}
				/>
				{supportsCoins && (
					<>
						<CoinsStat amount={coinsBalance} />
					</>
				)}
			</div>
		);
	};

	const showSideDrawer = () => {
		props.showSideDrawer();
	};

	const navigateToLeaderboard = () => {
		history.push('/leaderboard');
	};

	const { leaderboardUser, supportsCoins } = props;

	return (
		<div className="mobile-header">
			<div className="row">
				<div className="button market" onClick={props.openInstrumentsSlider}>
					<img src={require('../../../icons/instrumentChange.svg?url')} alt="change instrument" />
				</div>
				{leaderboardUser ? (
					<RankStat
						change={leaderboardUser.change}
						rank={leaderboardUser.rank}
						nickname={leaderboardUser.nickname}
						navigateToLeaderboard={navigateToLeaderboard}
					/>
				) : (
						<div className="logo-container">
							<NavLink exact to="/">
                <BrandLogo useRectangular className="logo-brand"/>
							</NavLink>
						</div>
				)}

				<div className="button" onClick={showSideDrawer}>
					<img src={require('../../../icons/drawerToggle.svg?url')} alt="" />
				</div>
			</div>
			{!isShortScreen && supportsCoins && (
				<div className="row">
					<LoyaltyStat />
				</div>
			)}
			<div className="row">{renderStats()}</div>
		</div>
	);
};

export default React.memo(MobileHeader);
