import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { ACCOUNT_UPDATED, clearUserLoginTokens, displayError, FETCH_COINS_EXCHANGE_RATE_REQUESTED, FETCH_GREY_LABEL_LOGO_REQUESTED, FETCH_MESSAGES_NEXT_PAGE_REQUESTED, FETCH_MESSAGES_REQUESTED, FETCH_MESSAGES_UNREAD_COUNT_REQUESTED, fetchCoinsExchangeRateReject, fetchCoinsExchangeRateSuccess, fetchMessagesNextPageReject, fetchMessagesNextPageSuccess, fetchMessagesReject, fetchMessagesSuccess, fetchMessagesUnreadCountReject, fetchMessagesUnreadCountSuccess, handleGreyLabelLogoError, setGreyLabelLogo, UPDATE_USER_REQUESTED, updateUser as updateUserAction, updateUserReject, updateUserSuccess, USER_SAVE_LANGUAGE } from "../actions";
import { getCoinsExchangeRate, getGreyLabelLogo, getMessages, getUnreadMessagesCount, updateUser } from "../services/crmService";
import { selectors } from "../index";
import { getConfig } from "../utils/config";
function* updateUserSaga(action) {
    const { standalone, communicationLanguage } = action.payload;
    try {
        const { user } = yield call(updateUser, { standalone, communicationLanguage });
        yield put(updateUserSuccess(user.communicationLanguage));
    }
    catch (e) {
        yield put(updateUserReject(e.message));
    }
}
function* fetchGreyLabelLogo() {
    try {
        const { logos } = yield call(getGreyLabelLogo);
        yield put(setGreyLabelLogo(logos));
    }
    catch (e) {
        yield put(handleGreyLabelLogoError());
        yield put(displayError("Failed fetching affiliate logo", e.message));
    }
}
function* updateUserLanguageSaga(action) {
    const { language, skipUserUpdate } = action.payload;
    const supportsUserUpdate = yield select(selectors.supportsUserUpdateSelector);
    if (supportsUserUpdate && !skipUserUpdate) {
        yield put(updateUserAction({ communicationLanguage: language }));
    }
}
function* fetchExchangeRate() {
    try {
        const { effectiveRate, rate, starsBonus } = yield call(getCoinsExchangeRate);
        yield put(fetchCoinsExchangeRateSuccess(rate, effectiveRate, starsBonus));
    }
    catch (e) {
        console.error(e);
        yield put(fetchCoinsExchangeRateReject(e.message));
    }
}
function* fetchMessagesSaga() {
    try {
        const { messages, pagination } = yield call(getMessages);
        yield put(fetchMessagesSuccess(messages, pagination));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesReject(e.message));
    }
}
function* fetchMessagesNextPageSaga() {
    try {
        const { offset, limit } = yield select(state => state.broker.messages.pagination);
        const { messages: newMessages, pagination } = yield call(getMessages, offset + limit);
        yield put(fetchMessagesNextPageSuccess(newMessages, pagination));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesNextPageReject(e.message));
    }
}
function* fetchMessagesUnreadCountSaga() {
    try {
        const { unreadCount } = yield call(getUnreadMessagesCount);
        yield put(fetchMessagesUnreadCountSuccess(unreadCount));
    }
    catch (e) {
        console.error(e);
        yield put(fetchMessagesUnreadCountReject(e.message));
    }
}
function* accountUpdatedSaga(action) {
    const { enabled } = action.payload;
    if (!enabled) {
        yield put(clearUserLoginTokens());
        const { cashier } = getConfig();
        window.location.replace(cashier.frontendURL);
    }
}
function* brokerSaga() {
    yield all([
        takeLatest(UPDATE_USER_REQUESTED, updateUserSaga),
        takeLatest(FETCH_COINS_EXCHANGE_RATE_REQUESTED, fetchExchangeRate),
        takeLatest(FETCH_MESSAGES_REQUESTED, fetchMessagesSaga),
        takeEvery(FETCH_MESSAGES_NEXT_PAGE_REQUESTED, fetchMessagesNextPageSaga),
        takeLatest(FETCH_MESSAGES_UNREAD_COUNT_REQUESTED, fetchMessagesUnreadCountSaga),
        takeLatest(USER_SAVE_LANGUAGE, updateUserLanguageSaga),
        takeLatest(FETCH_GREY_LABEL_LOGO_REQUESTED, fetchGreyLabelLogo),
        takeLatest(ACCOUNT_UPDATED, accountUpdatedSaga)
    ]);
}
export default brokerSaga;
