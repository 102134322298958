import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styles from './BrandLogo.module.scss';
import WebtraderLogo from '../../images/brands/wintrado/logo.svg?url';
import WebtraderLogoRectangular from '../../images/brands/wintrado/logo-mobile.png';
import GFMarketsLogo from '../../images/brands/gfmarkets/logo.svg?url';
import GFMarketsLogoRectangular from '../../images/brands/gfmarkets/logo-mobile.png';
import PropFundXLogo from '../../images/brands/propfundx/logo.svg?url';
import PropFundXLogoRectangular from '../../images/brands/propfundx/logo-mobile.svg?url';
import UTOCapitalLogo from '../../images/brands/utocapital/logo.svg?url';
import UTOCapitalLogoRectangular from '../../images/brands/utocapital/logo-mobile.png';
import { useAppSelector } from 'wintrado-api';
import classNames from 'classnames';
export const getBrandLogo = (useRectangular) => {
    // eslint-disable-next-line no-undef
    switch (__BRAND__) {
        case 'wintrado':
            return useRectangular ? WebtraderLogoRectangular : WebtraderLogo;
        case 'gfmarkets':
            return useRectangular ? GFMarketsLogoRectangular : GFMarketsLogo;
        case 'propfundx':
            return useRectangular ? PropFundXLogoRectangular : PropFundXLogo;
        case 'utocapital':
            return useRectangular ? UTOCapitalLogoRectangular : UTOCapitalLogo;
        default:
            return useRectangular ? WebtraderLogoRectangular : WebtraderLogo;
    }
};
const BrandLogo = ({ useRectangular = false, className }) => {
    const { data: affiliateLogo, isLoading: affiliateLogoIsLoading } = useAppSelector((state) => state.greyLabelLogo);
    const logoUrl = useMemo(() => {
        const affiliateTypeLogo = useRectangular ? affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.rectangularLogo : affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.squareLogo;
        return affiliateTypeLogo !== null && affiliateTypeLogo !== void 0 ? affiliateTypeLogo : getBrandLogo(useRectangular);
    }, [affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.rectangularLogo, affiliateLogo === null || affiliateLogo === void 0 ? void 0 : affiliateLogo.squareLogo, useRectangular]);
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: !affiliateLogoIsLoading && _jsx("img", { src: logoUrl, alt: `${__BRAND__}-logo`, className: styles.logo }) })));
};
export default React.memo(BrandLogo);
