import { all, call, put, takeLatest } from "redux-saga/effects";
import { accountUpdated, FETCH_USER_REQUESTED, fetchUserReject, fetchUserSuccess } from "../actions";
import { getUser } from "../services/oAuthService";
function* handleUserRequested() {
    var _a;
    try {
        // @ts-ignore
        const response = yield call(getUser);
        if (((_a = response.wallet) === null || _a === void 0 ? void 0 : _a.status) === "DISABLED") {
            yield put(accountUpdated({ enabled: false }));
            return;
        }
        yield put(fetchUserSuccess(response));
    }
    catch (e) {
        yield put(fetchUserReject(e.message));
    }
}
function* userSaga() {
    yield all([
        takeLatest(FETCH_USER_REQUESTED, handleUserRequested)
    ]);
}
export default userSaga;
