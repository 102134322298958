import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _t from 'counterpart';
import maintenance from '../../images/maintenance.svg?url';
import Button from 'components/Button/Button';
import BrandLogo from "components/BrandLogo/BrandLogo";
export const MaintenanceScreen = () => {
    const onRefreshClicked = () => {
        window.location.reload();
    };
    return (_jsxs("div", Object.assign({ className: "maintenance-screen" }, { children: [_jsx(BrandLogo, { useRectangular: true, className: "maintenance-screen__logo" }), _jsx("img", { src: maintenance, alt: "maintenance", className: "maintenance-screen__icon" }), _jsx("h2", Object.assign({ className: "maintenance-screen__title" }, { children: _t.translate('error.app_issues') })), _jsx("p", Object.assign({ className: "maintenance-screen__description" }, { children: _t.translate('error.maintenance_description') })), _jsx(Button, Object.assign({ gray: true, onClick: onRefreshClicked, className: "maintenance-screen__button" }, { children: _t.translate('main.refresh') }))] })));
};
