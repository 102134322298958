const config = {
    soundstyle: 'classic',
    appName: 'Wintrado Neo',
    appTitle: 'Wintrado - WebTrader Neo',
    cashier: {
        useIframe: true,
        baseURL: 'https://cashier.demo.wintrado.com',
        frontendURL: 'https://app.demo.wintrado.com',
    },
    oAuth: {
        clientId: 'webtrader-wintrado',
        clientSecret: 'RqofbEv6EsYpMjnc',
    },
    affiliatePortalUrl: 'https://affiliate.demo.wintrado.com',
    feedApi: {
        url: 'wss://gw-prod-01.demo.wintrado.com',
    },
    baseURL: 'https://gw-prod-01.demo.wintrado.com',
};
export default config;
