import React from "react";
import { NavLink } from "react-router-dom";
import { BackButton } from "components/BackButton";
import BrandLogo from "components/BrandLogo/BrandLogo";

export const MobilePageHeader = ({ isEmbeddedCashier = false }) => {
	return (
		<div className="mobile-page-header">
			<BackButton useCloseIcon={isEmbeddedCashier} />
				<NavLink exact to="/" className="mobile-page-header__logo">
          <BrandLogo useRectangular className="logo-brand"/>
				</NavLink>
		</div>
	);
};
